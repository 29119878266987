import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import AppDownload from '../components/Footer/AppDownload'

const AutoDownload = () => {
  const [isWeb, setIsWeb] = useState(false)
  useEffect(() => {
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    const isAndroid = /Android/.test(navigator.userAgent)
    if (isIOS) {
      window.location.href =
        'https://apps.apple.com/ng/app/fasta-pay/id1660439153'
    } else if (isAndroid) {
      window.location.href =
        'https://play.google.com/store/apps/details?id=com.fastapay.ng.users'
    } else {
      setIsWeb(true)
    }
  }, [])

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      {isWeb && <AppDownload />}
      {!isWeb && <div />}
    </Box>
  )
}

export default AutoDownload
