import { Button, Grid, IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router'
import appStore from '../../assets/footer/appStore.svg'
import playStore from '../../assets/footer/playStore.svg'
import SignUpForm from '../SignUpForm/SignUpForm'
import useStyles from './useStyles'

const Footer = () => {
  const classes = useStyles()
  const location = useLocation()
  const nav = useNavigate()

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    nav(location.pathname + '?modalOpened=true')
    setOpen(true)
  }

  const handleClose = () => setOpen(false)

  const notify = () => toast.success('You have successfuly subscribed')

  const date = new Date('2023-01-25')

  return (
    <Grid id="footer" container alignItems="center" className={classes.footer}>
      <Toaster position="top-right" reverseOrder={false} />

      <Grid item xs={12} sm={6} mb={{ xs: 8, sm: 0 }} data-aos="fade-right">
        <Box
          className={classes.btnWrapper}
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <IconButton disableRipple>
            <a href="https://apps.apple.com/ng/app/fasta-pay/id1660439153">
              <img src={appStore} alt="download on the App Store" />
            </a>
          </IconButton>
          <IconButton disableRipple>
            <a href="https://play.google.com/store/apps/details?id=com.fastapay.ng.users">
              {' '}
              <img src={playStore} alt="download on Google Play" />
            </a>
          </IconButton>
        </Box>
      </Grid>

      <Grid item xs={12} sm={6} className={classes.info}>
        <Typography className={classes.text1} data-aos="fade-up">
          Subcribe to be the first to get notification on our services when we
          launch
        </Typography>

        <Button
          variant="contained"
          sx={{
            backgroundColor: '#004266',
            height: '54px',
            width: '173px',
            margin: '0 auto'
          }}
          onClick={handleOpen}
        >
          Subscribe
        </Button>
      </Grid>

      <SignUpForm
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        notify={notify}
      />
    </Grid>
  )
}

export default Footer
