import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'
import leftBlob from '../../assets/header/leftBlob.svg'
import man from '../../assets/header/man.png'
import phone from '../../assets/header/phone.gif'
import point from '../../assets/header/point.svg'
import qrCode from '../../assets/header/qrCode2.png'
import spiral from '../../assets/header/spiral.svg'
import AppDownload from '../Footer/AppDownload'
import SignUpForm from '../SignUpForm/SignUpForm'
import useStyles from './useStyles'

const Header = () => {
  const classes = useStyles()
  const location = useLocation()
  const nav = useNavigate()

  useEffect(() => {
    if (!location.search.includes('modalOpened=true')) {
      setOpen(false)
    }
  }, [location])

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    nav(location.pathname + 'app/download')
    setOpen(true)
  }

  const handleClose = () => setOpen(false)

  const notify = () => toast.success('You have successfuly subscribed')

  return (
    <Box id="home" className={classes.header}>
      <Toaster position="top-right" />

      <img
        className={classes.leftBlob}
        src={leftBlob}
        alt="Left Blob"
        data-aos="fade"
      />
      <img
        className={classes.qrCode}
        src={qrCode}
        alt="QR Code"
        data-aos="fade"
      />
      <img
        className={classes.spiral}
        src={spiral}
        alt="Arrow"
        data-aos="fade"
      />
      <img className={classes.man} src={man} alt="Man" />
      <img className={classes.point} src={point} alt="Point" data-aos="fade" />
      <img
        className={classes.phone}
        src={phone}
        alt="Phone"
        data-aos="fade-right"
      />

      <Box className={classes.headingWrapper}>
        <Typography variant="h1" className={classes.heading} data-aos="fade-up">
          The simplest way to <span>send and receive money</span> while also
          earning money.
        </Typography>
        <Box sx={{ float: 'left' }}>
          {' '}
          <AppDownload marginTop="10px" />
        </Box>
      </Box>

      <SignUpForm
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        notify={notify}
      />
    </Box>
  )
}

export default Header
