import { Grid, IconButton } from '@mui/material'
import { Box } from '@mui/system'
import { useLocation, useNavigate } from 'react-router'
import appStore from '../../assets/footer/appStore.svg'
import playStore from '../../assets/footer/playStore.svg'
import useStyles from './useStyles'

const AppDownload = ({ marginTop = '100px' }) => {
  const classes = useStyles()
  const location = useLocation()
  const nav = useNavigate()

  return (
    <Grid sx={{ marginTop: marginTop }} container alignItems="center">
      <Grid item xs={12} sm={12} mb={{ xs: 8, sm: 0 }} data-aos="fade-right">
        <Box
          className={classes.btnWrapper}
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <IconButton disableRipple>
            <a href="https://apps.apple.com/ng/app/fasta-pay/id1660439153">
              <img src={appStore} alt="download on the App Store" />
            </a>
          </IconButton>
          <IconButton disableRipple>
            <a href="https://play.google.com/store/apps/details?id=com.fastapay.ng.users">
              {' '}
              <img src={playStore} alt="download on Google Play" />
            </a>
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  )
}

export default AppDownload
