import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useLocation, useNavigate } from 'react-router-dom'
import useStyles from './useStyles'

const Privacy = () => {
  const classes = useStyles()
  const location = useLocation()
  const nav = useNavigate()

  return (
    <Box id="privacy" className={classes.header}>
      <Box className={classes.content}>
        <Box className={classes.heading}>
          <Typography variant="h1">Fasta Technology Privacy Policy</Typography>
          <Typography variant="h6">Effective Date: [12/01/24]</Typography>
        </Box>

        <Box className={classes.textWrapper}>
          <Typography variant="h2" className={classes.subTitle}>
            Privacy Policy
          </Typography>
          <Typography className={classes.text}>
            Welcome to [Fasta Technology] and the Fastapay app, your
            revolutionary partner in digital payment solutions. At [Fasta
            Technology], we are committed to enhancing your payment experience
            with speed, security, and convenience. Our Fastapay app is at the
            forefront of this mission, offering an advanced QR code payment
            system that redefines efficiency. Each transaction with Fastapay is
            a seamless payment process and an opportunity to benefit from our
            rewards program. Earn points every time you use Fastapay, which can
            then be redeemed in future transactions for added value, or choose
            to make a difference by donating these points to a charity
            foundation of your choice. Embrace the future of payments with
            Fastapay, where every transaction is a step towards innovation and
            social responsibility.
          </Typography>
        </Box>

        <Box className={classes.textWrapper}>
          <Typography variant="h2" className={classes.subTitle}>
            Data Access, Collection, Use, and Sharing
          </Typography>
          <Typography className={classes.text}>
            Our privacy policy thoroughly details the ways in which the app
            accesses, gathers, utilizes, and disseminates user information. We
            prioritize safeguarding your privacy and maintaining openness. This
            document specifies the categories of personal and sensitive user
            data that are obtained, collected, utilized, and disseminated, and
            identifies any entities that might receive this data.
          </Typography>
        </Box>

        <Box className={classes.textWrapper}>
          <Typography variant="h2" className={classes.subTitle}>
            Access and Collection
          </Typography>
          <Typography className={classes.text}>
            The Fastapay application gathers specific personal details from its
            users, including their Name, Phone Number, Contact Information,
            Email Address, and Physical Address. This information is obtained
            when users input data during the registration process and while
            utilizing our services. Moreover, automatic data collection can
            happen via cookies and similar technologies, along with information
            you provide or from third-party services to which you have given
            access.
          </Typography>
        </Box>
        <Box className={classes.textWrapper}>
          <Typography variant="h2" className={classes.subTitle}>
            Data Usage
          </Typography>
          <Typography className={classes.text}>
            The gathered information is utilized for several objectives: to
            deliver and tailor our services, enable communication with users,
            manage orders and payments, and improve and refine our services.
            Additionally, we might examine user activities to gain business
            insights.
          </Typography>
        </Box>
        <Box className={classes.textWrapper}>
          <Typography variant="h2" className={classes.subTitle}>
            Third-Party Sharing
          </Typography>
          <Typography className={classes.text}>
            Your personal information is not disclosed to third parties, except
            when mandated by law or with your clear permission.
          </Typography>
        </Box>
        <Box className={classes.textWrapper}>
          <Typography variant="h2" className={classes.subTitle}>
            Data Retention and Deletion Policy
          </Typography>
          <Typography className={classes.text}>
            We are committed to ensuring data security and user privacy. Our
            policy for data retention and deletion is detailed below:
            <li>
              Data Retention: Your data is kept only as long as needed for the
              objectives stated in this Privacy Policy, or for a longer duration
              if legally necessary. The duration of retention differs based on
              the data category and its intended use.
            </li>
            <li>
              Data Deletion: We will swiftly and securely erase your personal
              and sensitive user data either at your request or when it is no
              longer necessary for the reasons mentioned in this Privacy Policy.
              For data deletion requests, please reach out to us using the
              contact details provided.
            </li>
          </Typography>
        </Box>
        <Box className={classes.textWrapper}>
          <Typography variant="h2" className={classes.subTitle}>
            User Rights
          </Typography>
          <Typography className={classes.text}>
            You have the following rights regarding your data:
            <br />
            <li>Right to access your data.</li>
            <li>Right to correct your data.</li>
            <li>Right to delete your data.</li>
            <li>Right to restrict the processing of your data.</li>
            <li>Right to data portability.</li>
            <li>Right to object to the processing of your data.</li>
          </Typography>
        </Box>
        <Box className={classes.textWrapper}>
          <Typography variant="h2" className={classes.subTitle}>
            Security Measures
          </Typography>
          <Typography className={classes.text}>
            We implement robust security measures to protect your data,
            including encryption, access controls, and regular security
            assessments.
          </Typography>
        </Box>
        <Box className={classes.textWrapper}>
          <Typography variant="h2" className={classes.subTitle}>
            Cookies and Tracking Technologies
          </Typography>
          <Typography className={classes.text}>
            We use cookies and similar technologies to enhance user experiences.
            You can manage your cookie preferences in your browser settings.
          </Typography>
        </Box>
        <Box className={classes.textWrapper}>
          <Typography variant="h2" className={classes.subTitle}>
            Location Data
          </Typography>
          <Typography className={classes.text}>
            If our services use location data, we will request your permission
            and explain the purpose of this data usage.
          </Typography>
        </Box>
        <Box className={classes.textWrapper}>
          <Typography variant="h2" className={classes.subTitle}>
            Children's Privacy
          </Typography>
          <Typography className={classes.text}>
            Our services are not intended for children under the age of 18. We
            do not knowingly collect data from children under 18. If you believe
            we have inadvertently collected data from a child under 18, please
            contact us.
          </Typography>
        </Box>
        <Box className={classes.textWrapper}>
          <Typography variant="h2" className={classes.subTitle}>
            Changes to the Privacy Policy
          </Typography>
          <Typography className={classes.text}>
            This Privacy Policy may be updated to reflect changes in our
            practices. We will notify users of significant changes.
          </Typography>
        </Box>
        <Box className={classes.textWrapper}>
          <Typography variant="h2" className={classes.subTitle}>
            Contact Information
          </Typography>
          <Typography className={classes.text}>
            If you have any questions or concerns about our Privacy Policy,
            please contact us at:{' '}
            <a href="mailto:info@fastapay.ng">info@fastapay.ng</a>,{' '}
            <a href="tel:+2347030674881">+2347030674881</a>
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default Privacy
