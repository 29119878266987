import { Box } from '@mui/system'
import Footer from '../components/Footer/Footer'
import Privacy from '../components/Policy/Privacy'
import Social from '../components/Social/Social'

const PrivacyPolicy = () => {
  return (
    <Box>
      <Privacy />
      <Footer />
      <Social />
    </Box>
  )
}

export default PrivacyPolicy
