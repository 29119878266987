import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  header: {
    position: 'relative',
    minHeight: '130vh',
    padding: '10vw 10vw 0',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      marginTop: '30px',
      minHeight: '640px',
      padding: '10vw 5vw 0'
    }
  },
  content: {
    width: '80%',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  subTitle: {
    textDecoration: 'none',
    paddingBottom: '20px',
    fontWeight: 'bold'
  },
  heading: {
    marginBottom: '50px'
  },
  textWrapper: {
    marginBottom: '50px'
  },
  text: {
    color: '#000',
    fontSize: '16px',
    lineHeight: '1.25em',
    padding: '0 0 1em 0',
    textAlign: 'justify'
  }
}))

export default useStyles
