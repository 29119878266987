import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './aos'
import NavBar from './components/NavBar/NavBar'
import NotFound from './pages/404'
import AutoDownload from './pages/AutoDownload'
import Business from './pages/Business'
import Home from './pages/Home'
import PrivacyPolicy from './pages/PrivacyPolicy'
import TermsAndCondition from './pages/TermsAndConditions'
import { theme } from './themes/theme'

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/business" element={<Business />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsAndCondition />} />
          <Route path="/a/:id" element={<AutoDownload />} />
          <Route path="/w/:id" element={<AutoDownload />} />
          <Route path="/app/download" element={<AutoDownload />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
