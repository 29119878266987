import { Box } from '@mui/system'

const TermsAndCondition = () => {
  return (
    <Box>
      <p>TermsAndCondition</p>
    </Box>
  )
}

export default TermsAndCondition
