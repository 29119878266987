import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: 'white'
      }}
    >
      <Box
        sx={{
          textAlign: 'center'
        }}
      >
        <Typography variant="h1" style={{ color: '#04ADE8' }}>
          404
        </Typography>

        <Button
          variant="contained"
          onClick={() => {
            navigate('/')
          }}
        >
          Go Home
        </Button>
      </Box>
    </Box>
  )
}

export default NotFound
